/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
    const script = document.createElement('script');
    script.src =
      'https://platform.illow.io/banner.js?siteId=25438428-b0e5-41a5-b873-b234ae0ad2db';
    script.async = true;
    document.head.appendChild(script);
  };